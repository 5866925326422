<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("lotUnitBuildingNumber")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.lot }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationAreaOfLot")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.extent }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationAreaInRoad")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.excavation_area_in_road }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("locationOnProperty")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.direction_of_road }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("typeOfWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.work_type }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("gateCode")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.gate_code }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("additionalComments")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.remarks }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("utahBoringLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.boring ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("utahWhiteFlagLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.white_paint ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("utahBlastingLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.blasting ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
